import axios from "axios";

const buscarAdministradoresService = async () => {
    const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/administrador/empleados/administradores`,
      config
    );
    return [true, response.data];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
}

const buscarEmpleadosPorLocalService = async (id) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-empleados-por-local/${id}`,
      config
    );
    return [true, response.data];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
}

const buscarAdministradoresPorLocalService = async (id) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-administradores-por-local/${id}`,
      config
    );
    return [true, response.data];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
}

export{
    buscarAdministradoresService,
    buscarEmpleadosPorLocalService,
    buscarAdministradoresPorLocalService
}