import { useEffect, useState } from "react";
import Carga from "../../../componentes/cargando/Cargando";
import { useAuth } from "../../../AuthContext";
import { useParams } from "react-router-dom";
import { buscarVentasDuenoPorId } from "../../../service/dueno/buscarVentas";
import {
  mensajeError,
  mensajeErrorBloqueo,
  mensajeErrorNoAutorizado,
  mensajeErrorPersonalizado,
} from "../../../helpers/mensajesSwalError";
import { formatearAPesos, formatoFecha } from "../../../helpers/formatos";
import { useNavigate } from "react-router-dom";

const VerVenta = () => {
  const [cargando, setCargando] = useState(true);
  const { verificacionToken } = useAuth();
  const [ventasEmpleados, setVentasEmpleados] = useState([]);
  const [metodosPagos, setMetodosPagos] = useState([]);
  const [descuentos, setDescuentos] = useState([]);
  const [venta, setVenta] = useState([]);
  const [local, setLocal] = useState([]);
  const { id } = useParams();
  let totalVentaEmpleados = 0;
  let totalVentaMetodos = 0;
  const navigate = useNavigate();

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    buscarVentaPorId(id);
  }, []);

  const buscarVentaPorId = async (id) => {
    const respuesta = await buscarVentasDuenoPorId(id);

    if (respuesta[0]) {
      setVentasEmpleados(respuesta[1]);
      setMetodosPagos(respuesta[2]);
      setDescuentos(respuesta[3]);
      setVenta(respuesta[4]);
      setLocal(respuesta[5]);
    } else {
      if (respuesta[1] === 400) {
        mensajeErrorPersonalizado([2]);
      } else if (respuesta[1] === 401) {
        mensajeErrorNoAutorizado();
      } else if (respuesta[1] === 403) {
        mensajeErrorBloqueo();
      } else {
        mensajeError();
      }
    }
    setCargando(false);
  };

  return (
    <div>
      {cargando ? (
        <div>
          <Carga />
        </div>
      ) : (
        <div>
          {cargando ? (
            <Carga />
          ) : (
            <div className="container">
              <div className="d-flex justify-content-start my-3">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => navigate(-1)}
                >
                  Volver atrás
                </button>
              </div>
              <div
                className={
                  local.kpi < venta.venta_total
                    ? "alert alert-primary"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <h2>Ventas del Día {formatoFecha(venta.createdAt)}</h2>
              </div>
              <div
                className={
                  local.kpi < venta.venta_total
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <div className="row mb-4">
                  <h2>Ventas por Empleado</h2>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Empleado</th>
                        <th>Cantidad de Clientes Atendidos</th>
                        <th>Total Ventas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ventasEmpleados.map((empleado) => {
                        totalVentaEmpleados += Number(empleado.monto_total);
                        return (
                          <tr key={empleado.usuario_id}>
                            <td>{empleado.nombre}</td>
                            <td>{empleado.cantidadCliente}</td>
                            <td>{formatearAPesos(empleado.monto_total)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="mt-4">
                    <h5>
                      Total de Ventas de Empleados:{" "}
                      {formatearAPesos(totalVentaEmpleados)}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className={
                  local.kpi < venta.venta_total
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <div className="row mb-4">
                  <h2>Ventas por Método de Pago</h2>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Método de Pago</th>
                        <th>Total Ventas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {metodosPagos.map((metodo) => {
                        totalVentaMetodos += Number(metodo.total);
                        return (
                          <tr key={metodo.id}>
                            <td>{metodo.nombre}</td>
                            <td>
                              {metodo.total !== "0"
                                ? `${formatearAPesos(metodo.total)}`
                                : "No hubo venta"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="mt-4">
                    <h5>
                      Total de Ventas Métodos de Pago:{" "}
                      {formatearAPesos(totalVentaMetodos)}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className={
                  local.kpi < venta.venta_total
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <div className="row mb-4">
                  <h2>Descuentos y Comentarios</h2>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Descuento</th>
                        <th>Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={descuentos.id}>
                        <td>
                          {descuentos.descuento !== "0"
                            ? `${formatearAPesos(descuentos.descuento)}`
                            : "No se aplicó descuento"}
                        </td>
                        <td>{descuentos.comentario || "Sin comentario"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className={
                  local.kpi < venta.venta_total
                    ? "alert alert-info"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <div className="mt-4 py-5">
                  <h4>
                    Total de Venta del Día: {formatearAPesos(venta.venta_total)}
                  </h4>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VerVenta;
